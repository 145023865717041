import * as yup from "yup";

export type FormValues = {
  organizationName: string;
  identificationCode: string;
  iban: string;
  amount: number;
  paymentPurpose: string;
};

export const validationSchema = yup.object({
  organizationName: yup
    .string()
    .required("Вкажіть 'Юридичну назву компанії або ФОП'"),
  identificationCode: yup
    .string()
    .min(8, "'ЄДРПОУ/РНОКПП' має містити 8 або більше символів")
    .max(13, "'ЄДРПОУ/РНОКПП' має містити менше ніж 13 символів")
    .required("Вкажіть 'ЄДРПОУ/РНОКПП'"),
  iban: yup
    .string()
    .min(33, "'IBAN' має містити 29 символів")
    .max(33, "'IBAN' має містити 29 символів")
    .matches(/^UA \d{2} \d{6} \d{5} \d{14}$/, "Неправильний формат 'IBAN'")
    .required("Вкажіть 'IBAN'"),
  amount: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable()
    .moreThan(0, "Вкажіть 'Суму'")
    .required("Вкажіть 'Суму'"),
  paymentPurpose: yup.string().required("Вкажіть 'Призначення платежу'"),
});
