export default (
  totalAmount: number,
  discountAmount: number,
  isRound: boolean = true
): number => {
  let roundPrice: number = Number(totalAmount.toFixed(2));

  roundPrice = totalAmount - discountAmount;

  if (isRound) {
    roundPrice = Math.round(roundPrice * 10) / 10;
  }

  return roundPrice;
};
