import React, { FC } from "react";
import { useController, useFormContext } from "react-hook-form";

import TextField from "@mui/material/TextField";
import { Box, Typography } from "@mui/material";

import { TextInputProps } from "../types";

import { mainStyles } from "styles";
import { styles } from "./styles";

const StyledFormInput: FC<TextInputProps> = (props) => {
  const { name, title, rules, defaultValue = "", ...inputProps } = props;

  const formContext = useFormContext();
  const {
    control,
    formState: { errors: { [name]: { message = "" } = {} } = {} },
  } = formContext;

  const { field } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  return (
    <Box mb={2} textAlign="left">
      {title && (
        <Typography sx={[mainStyles.ui2, styles.inputTitle]} mb={1}>
          {title}
        </Typography>
      )}
      <TextField
        {...inputProps}
        sx={styles.input}
        error={!!message}
        onChange={field.onChange}
        value={field.value}
        helperText={message}
      />
    </Box>
  );
};

export default StyledFormInput;
