import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import { Tooltip } from "components/Tooltip";

import { ReactComponent as IconCopy } from "assets/images/icon_copy.svg";
import { ReactComponent as IconCheckCopy } from "assets/images/icon_check_copy.svg";

import { actionCreators } from "store/Notification";
import { isMobile } from "react-device-detect";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

import { grey100, mainStyles } from "styles";

interface InfoItemProps {
  id: string;
  title: string;
  value: string;
  copyEnabled?: boolean;
  copyWithoutSpaces?: boolean;
}

const InfoItem: React.FC<InfoItemProps> = ({
  id,
  title,
  value,
  copyEnabled,
  copyWithoutSpaces,
}) => {
  const dispatch = useDispatch();
  const [isCopied, setIsCopied] = useState(false);

  const onCopy = async () => {
    analytics().sendEvent(`${AnalyticsEvents.iban_oplata_copy_}${id}`);
    try {
      const valueToCopy = copyWithoutSpaces ? value.replace(/\s+/g, "") : value;
      await navigator.clipboard.writeText(valueToCopy);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    } catch (err) {
      dispatch(
        actionCreators.setNotification({
          message: "Трапилась помилка при копіюванні. Спробуйте пізніше",
          severity: "error",
        })
      );
    }
  };
  return (
    <Box mb={1}>
      <Typography sx={styles.title}>{title}</Typography>
      <Box sx={styles.content}>
        <Typography sx={styles.value}>{value}</Typography>
        {copyEnabled && (
          <Tooltip
            placement="top"
            {...(isMobile ? { open: isCopied } : {})}
            title={
              isCopied ? (
                <Box display="flex" alignItems="center">
                  <IconCheckCopy />
                  <Typography sx={[mainStyles.ui2, { color: grey100 }]} ml={1}>
                    Скопійовано
                  </Typography>
                </Box>
              ) : (
                <Typography sx={[mainStyles.ui2, { color: grey100 }]}>
                  Копіювати
                </Typography>
              )
            }
            arrow
          >
            <IconButton
              disableRipple
              disableTouchRipple
              onClick={onCopy}
              sx={{ padding: 0 }}
            >
              <IconCopy />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

const styles = {
  title: {
    fontFamily: "Inter",
    color: "#707070",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "15.6px",
    marginBottom: "4px",
    textAlign: "left",
    letterSpacing: "0px",
  },
  value: {
    fontFamily: "Inter",
    color: "#191B1F",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "18.2px",
    textAlign: "left",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "inherit",
    marginRight: "12px",
    letterSpacing: "0px",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "start",
  },
};

export default InfoItem;
