import React, { FC } from "react";
import { useController, useFormContext } from "react-hook-form";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import InputMask from "react-input-mask";

import { MaskedTextInputProps } from "../types";

import { mainStyles } from "styles";
import { styles } from "./styles";

const StyledFormMaskedInput: FC<MaskedTextInputProps> = (props) => {
  const { mask, name, title, rules, defaultValue = "", ...inputProps } = props;

  const formContext = useFormContext();
  const {
    control,
    formState: { errors: { [name]: { message = "" } = {} } = {} },
  } = formContext;

  const {
    field: { ...field },
  } = useController({ name, control, rules, defaultValue });

  return (
    <Box mb={2} textAlign="left">
      {title && (
        <Typography sx={[mainStyles.ui2, styles.inputTitle]} mb={1}>
          {title}
        </Typography>
      )}
      <InputMask
        mask={mask}
        onChange={field.onChange}
        value={field.value}
        maskChar={null}
      >
        {(inputMaskProps) => (
          <TextField
            {...inputProps}
            {...inputMaskProps}
            sx={styles.input}
            error={!!message}
            helperText={message}
          />
        )}
      </InputMask>
    </Box>
  );
};

export default StyledFormMaskedInput;
