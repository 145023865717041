import React from "react";

import { FormProvider, UseFormReturn } from "react-hook-form";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import CircularProgress from "@mui/material/CircularProgress";

import StyledFormInput from "components/FormInput/New/FormInput";
import StyledFormCashInput from "components/FormInput/New/FormCashInput";
import StyledFormMaskedInput from "components/FormInput/New/FormMaskedInput";

import { FormValues } from "../validation";

import { grey000, grey800, mainStyles } from "styles";

interface Props {
  onSubmit: () => void;
  methods: UseFormReturn<FormValues>;
  isLoading: boolean;
}

const Form: React.FC<Props> = (props) => {
  const { isLoading, methods, onSubmit } = props;

  const isXs = useMediaQuery("(max-width: 600px)");

  return (
    <Box sx={styles.formContainer}>
      <Box mb={{ xs: 2, sm: 4 }}>
        <Typography sx={mainStyles.h3}>
          Створіть своє посилання на оплату
        </Typography>
      </Box>

      <FormProvider {...methods}>
        <StyledFormInput
          title="Юридична назва компанії або ФОП"
          name="organizationName"
          fullWidth
          inputProps={{ maxLength: 70 }}
        />
        <StyledFormInput
          title="ЄДРПОУ/РНОКПП"
          name="identificationCode"
          fullWidth
          inputProps={{ maxLength: 13 }}
        />
        <Typography sx={[mainStyles.ui2, styles.codeText]} mt={-1} mb={2}>
          Для розрахунків з юридичною особою (ТОВ\ТзОВ) потрібен ЄДРПОУ, а для
          розрахунків з фізичною особою (ФОП) потрібен Ідентифікаційний код
          (РНОКПП).
        </Typography>
        <StyledFormMaskedInput
          mask="UA 99 999999 99999 99999999999999"
          title="IBAN"
          name="iban"
          fullWidth
          inputProps={{
            inputMode: "numeric",
          }}
        />
        <StyledFormCashInput
          title="Сума"
          name="amount"
          fullWidth
          inputProps={{
            inputMode: "numeric",
          }}
        />
        <StyledFormInput
          title="Призначення платежу"
          name="paymentPurpose"
          fullWidth
        />
      </FormProvider>
      <Button
        sx={[mainStyles.primaryButton, styles.createButton]}
        onClick={onSubmit}
        size="large"
        variant="contained"
        fullWidth
        disabled={isLoading}
      >
        {isLoading ? (
          <CircularProgress size={24} color="inherit" thickness={10} />
        ) : (
          <Typography sx={mainStyles.primaryButtonText}>
            {`Створити посилання${isXs ? "" : " на оплату"}`}
          </Typography>
        )}
      </Button>
    </Box>
  );
};

const styles = {
  formContainer: {
    padding: { xs: "16px", sm: "32px" },
    borderRadius: "20px",
    border: `8px #E1E8FA solid`,
    backgroundColor: grey000,
  },
  createButton: {
    marginTop: "16px",
  },
  codeText: {
    color: grey800,
    textAlign: "left",
  },
};

export default Form;
