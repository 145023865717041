import { blue600, grey000, grey200, grey900 } from "styles";

export const styles = {
  page: {
    height: "100vh",
    paddingTop: "40px",
    paddingBottom: "80px",
    backgroundColor: "#F5F5F5",
  },
  pageLayout: {
    display: "flex",
    justifyContent: "center",
  },
  ibanReceipt: {
    padding: "16px",
    border: "1px solid #E0E0E0",
    maxWidth: "360px",
  },
  heading: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "22.4px",
  },
  amountContainer: {
    backgroundColor: grey200,
    padding: "12px 16px",
    marginTop: "16px",
    borderRadius: "8px",
  },
  text: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "15.6px",
    marginBottom: "4px",
  },
  amount: {
    fontFamily: "Inter",
    color: grey900,
    fontSize: "22px",
    fontWeight: "500",
    lineHeight: "31.9px",
  },
  qrContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: blue600,
    padding: "8px",
    marginLeft: "16px",
    borderRadius: "8px",
    maxWidth: "188px",
    textAlign: "center",
  },
  qrWrapper: {
    backgroundColor: grey000,
    padding: "4px",
  },
};
