import React, { useState } from "react";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Accordion from "@mui/material/Accordion";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

import ExpandIcon from "assets/images/expand-icon.jpg";
import { mainStyles } from "styles";

const accordionData = [
  {
    title: "Чи потрібен чек при оплаті на IBAN?",
    text: (
      <>
        Нагадуємо, що при розрахунку на IBAN чек (фіскалізація) не потрібен.
        Якщо ж ви хочете також приймати готівкові оплати та видавати фіскальні
        чеки — потрібно зареєструвати ПРРО{" "}
        <Link
          target="_blank"
          href="https://fopkasa.com/registration/phone-number"
          sx={{ cursor: "pointer" }}
        >
          ФОПкаса
        </Link>
        .
      </>
    ),
  },
  {
    title: "Що таке IBAN?",
    text: "З 5 серпня 2019 року в Україні діє міжнародний номер банківського рахунку IBAN (International Bank Account Number) відповідно до стандарту №13616 Міжнародної організації з стандартизації ISO і Європейського комітету з банківських стандартів ECBS. Такий номер складається з 29 символів.",
  },
  {
    title:
      "З яких пристроїв та через які банківські застосунки клієнти можуть відкрити QR код на здійснити оплату?",
    text: "Для користування IBAN oplata підійде будь-який пристрій з банківськими застосунками (смартфони або tablet). Автоматично працюватиме з Приватбанк, Монобанк та близько двадцяти додатків інших провідних банків. Також завжди можна вставити реквізити вручну у всіх інших випадках.",
  },
  {
    title: "Чи може клієнт змінити суму чи призначення платежу?",
    text: "Так. Коли клієнт переходить за QR кодом, то усі дані вносяться автоматично, проте є можливість перевірити інформацію та за потреби внести зміни вручну перед підтвердженням оплати.",
  },
  {
    title: "Чи може клієнт допустити помилку при оплаті?",
    text: "Так, оскільки у нього є можливість вносити зміни у суму та призначення. Для цього потрібно звіряти оплати для належного контролю.",
  },
  {
    title: "Чи є ліміти на суми переказів через IBAN oplata?",
    text: "З 1 жовтня 2024 року Національний банк України встановлює обмеження на перекази коштів між банківськими картками фізичних осіб. Такі транзакції позначаються як P2P (Person-To-Person). НБУ має намір на півроку встановити ліміти на переказ коштів (вихідні P2P-перекази) до 150 000 грн на місяць.\nПepекази підпpиємцям не виняток, бо часто онлайн- чи офлайн-магазини пpиймають кошти на особисті карткові рахунки, які не пpизначені для підпpиємницької діяльності.\nДoxоди, щo надходять на карткові рахунки фізичної особи підлягають декларуванню, і за них потрібно сплачувати податки у розмірі – 18% ПДФО і 1,5% військовий збіp.\nДля ведення підприємницької діяльності потрібно використовувати окремий рахунок для ФОП та приймати оплату за IBAN-реквізитами. Це зручно робити з безкоштовним сервісом IBAN oplata. Створюйте QR-коди для швидких оплат та надсилате клієнтам у зручний спосіб. Це зекономить час та вбереже від штрафів.",
  },
];

const FAQ: React.FC = () => {
  const [expanded, setExpanded] = useState<number | false>(false);

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Container sx={styles.faqBlock}>
      <Box sx={styles.topHeading}>
        <Typography sx={mainStyles.h2} mb={7.5}>
          Часті запитання
        </Typography>
        <Box>
          {accordionData.map((item, index) => (
            <Accordion
              key={index}
              elevation={0}
              sx={styles.accordion}
              expanded={expanded === index}
              onChange={handleChange(index)}
            >
              <AccordionSummary
                sx={styles.accordionHeader}
                expandIcon={<img style={styles.image} src={ExpandIcon} />}
              >
                <Typography sx={mainStyles.h3}>{item.title}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={styles.accordionContent}>
                <Typography sx={[mainStyles.body1, { whiteSpace: "pre-line" }]}>
                  {item.text}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

const styles = {
  faqBlock: {
    textAlign: "center",
    width: { xs: "100%", md: "70%" },
    paddingTop: { xs: "56px", sm: "96px" },
    paddingBottom: { xs: "56px", sm: "96px" },
  },
  image: {
    borderRadius: "100px",
  },
  topHeading: {
    textAlign: "center",
    marginBottom: "60px",
  },
  accordion: {
    backgroundColor: "#F5F8FF",
    textAlign: "left",
    "&:before": {
      display: "none",
    },
    marginBottom: "24px",
  },
  accordionHeader: {
    paddingTop: "4px",
    paddingBottom: "4px",
    paddingLeft: { xs: "20px", sm: "40px" },
    paddingRight: { xs: "20px", sm: "40px" },

    "&.Mui-expanded": {
      paddingTop: "4px",
      paddingBottom: "0",
      paddingLeft: { xs: "20px", sm: "40px" },
      paddingRight: { xs: "20px", sm: "40px" },
    },
  },
  accordionContent: {
    paddingTop: "0",
    paddingBottom: "24px",
    paddingLeft: { xs: "20px", sm: "40px" },
    paddingRight: { xs: "20px", sm: "40px" },
  },
};

export default FAQ;
