import React, { DragEvent, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { FormInput } from 'components/FormInput';
import analytics from 'services/analytics';
import {AnalyticsEvents} from 'shared/constants/analytics';

const Input = styled('input')({
  display: 'none',
});

interface Props {
  subTitle?: string,
  isShowDescription?: boolean
}

const KeyUpload: React.FC<Props> = (props) => {
  const { subTitle, isShowDescription = true } = props;
  const [dropActive, setDropActive] = useState(false);
  const { register, watch, setValue } = useFormContext();
  const watchFiles = watch('files');

  const onDragLeave = () => setDropActive(false);

  const onDragOver = (e: DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    setDropActive(true);
  };

  const onDrop = (e: DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    setValue('files', e.dataTransfer.files);
    onDragLeave();
  };

  const renderContent = () => {
    if (watchFiles && watchFiles.length) {
      return (
        <>
          {Array.from<File>(watchFiles).map((file, index) => (
            <Typography key={`${file.name}-${index}`} mb={1} fontWeight='lighter'>{file.name}</Typography>
          ))}
          <Button size='large' variant='contained' color='inherit' component='span' onClick={() => {
            analytics().sendEvent(AnalyticsEvents.cp_key_upload_choose_digital_key);
          }} >Обрати інший файл</Button>
        </>
      )
    }

    return (
      <>
        <Button size='large' variant='contained' component='span' onClick={() => {
          analytics().sendEvent(AnalyticsEvents.cp_key_upload_choose_digital_key);
        }}>Оберіть файл</Button>
        <Typography mt={1} fontWeight='lighter'>або перетягніть його сюди</Typography>
      </>
    );
  };

  return (
    <div>
      <Box mb={2}>
        <Typography>{ subTitle || 'Будь ласка, завантажте ключ.' }</Typography>
        { isShowDescription ?
          <>
            <Typography fontWeight='lighter'>
              Ключ КЕП/ЕЦП потрібен, щоби завантажити дані з Державної фіскальної служби та надалі видавати фіскальні чеки.
              У деяких випадках ключ складається з двох файлів. Завантажте обидва файли за наявності.
            </Typography>
            <Typography fontWeight='lighter'>
              Якщо у вас з’явилися запитання, зверніться до нашої Служби турботи
            </Typography>
          </> :
          null
        }
      </Box>
      <Box
        display='block'
        textAlign='center'
        htmlFor='key-upload-zone'
        pt={5.5}
        pb={5.5}
        sx={{
          backgroundColor: ({ colors }) => colors.lightBlue,
          border: ({ colors }) => `1px solid ${dropActive ? colors.body : colors.strokeLight}`,
        }}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        onDrop={onDrop}
        component='label'
      >
        <Input {...register('files')} id='key-upload-zone' accept='.dat,.pfx,.pk8,.zs2,.jks,.cer,.crt' type='file' multiple />
        {renderContent()}
      </Box>
      <Box mt={2}>
        <Typography mb={.5} variant='body2'>
          ПАРОЛЬ ДО КЛЮЧА:
        </Typography>
        <FormInput name='password' fullWidth placeholder='Введіть пароль до ключа КЕП/ЕЦП' type='password' />
      </Box>
    </div>
  )
};

export default KeyUpload;
