import { Action, Reducer } from "redux";
import { ApiError } from "types";
import {
  CreateReceiptProductReturnRequest,
  CreateReceiptReturnResponse,
  ReceiptResponse,
  ReceiptReturnResponse,
} from "../api/models";
import { AppThunkAction } from "./";
import {
  actionCreators as notificationActions,
  SetNotificationAction,
} from "./Notification";
import { ReceiptApi, ReceiptReturnApi } from "api";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const receiptReturnApi = new ReceiptReturnApi();
const receiptApi = new ReceiptApi();

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ProductToReturn {
  receiptProductId: number;
  receiptProductQuantity: number;
  quantity: number | null;
  name: string;
  unit: string;
  price: number;
  totalAmount: number;
  discountAmount: number;
}

export interface ReceiptReturnState {
  searchReceiptResult: ReceiptResponse | null;
  productsToReturn: Map<number, ProductToReturn> | null;
  receiptReturn: ReceiptReturnResponse | null;
}

enum ReceiptReturnActionTypes {
  SET_SEARCH_RECEIPT_RESULT = "SET_SEARCH_RECEIPT_RESULT",
  SET_PRODUCTS_TO_RETURN = "SET_PRODUCTS_TO_RETURN",
  SET_RECEIPT_RETURN = "SET_RECEIPT_RETURN",
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SetSearchReceiptReturnAction {
  type: ReceiptReturnActionTypes.SET_SEARCH_RECEIPT_RESULT;
  payload: ReceiptResponse | null;
}

export interface SetProductToReturn {
  type: ReceiptReturnActionTypes.SET_PRODUCTS_TO_RETURN;
  payload: Map<number, ProductToReturn> | null;
}

export interface SetReceiptReturn {
  type: ReceiptReturnActionTypes.SET_RECEIPT_RETURN;
  payload: ReceiptReturnResponse | null;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
  | SetSearchReceiptReturnAction
  | SetProductToReturn
  | SetReceiptReturn
  | SetNotificationAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  setSearchReceiptReturn: (
    payload: ReceiptResponse | null
  ): SetSearchReceiptReturnAction => ({
    type: ReceiptReturnActionTypes.SET_SEARCH_RECEIPT_RESULT,
    payload,
  }),
  setProductsToReturn: (
    payload: Map<number, ProductToReturn> | null
  ): SetProductToReturn => ({
    type: ReceiptReturnActionTypes.SET_PRODUCTS_TO_RETURN,
    payload,
  }),
  setReceiptReturn: (
    payload: ReceiptReturnResponse | null
  ): SetReceiptReturn => ({
    type: ReceiptReturnActionTypes.SET_RECEIPT_RETURN,
    payload,
  }),
  findReceiptReturn:
    (
      receiptNumber: string,
      onSuccess?: () => void,
      onError?: (response?: ApiError) => void
    ): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      try {
        const { data } = await receiptApi.receiptFindGet(receiptNumber);

        const initialProductValue = new Map<number, ProductToReturn>(
          (data?.receiptProducts || []).map((product) => [
            product.receiptProductId!,
            {
              receiptProductId: product.receiptProductId!,
              receiptProductQuantity: product.quantity || 1,
              name: product.name || "",
              quantity: product.quantity || 1,
              unit: product.unit.symbol || "",
              price: product.price || 0,
              totalAmount: product.totalAmount || 0,
              discountAmount: product.discountAmount || 0,
            },
          ])
        );

        dispatch(actionCreators.setSearchReceiptReturn(data));
        dispatch(actionCreators.setProductsToReturn(initialProductValue));

        if (onSuccess) {
          onSuccess();
        }
      } catch ({ response }) {
        const { data } = response as ApiError;

        if (onError) {
          onError(response as ApiError);
        } else {
          dispatch(
            notificationActions.setNotification({
              message: data.title,
              severity: "error",
            })
          );
        }
      }
    },
  returnProducts:
    (
      receiptUid: string,
      receiptProducts: CreateReceiptProductReturnRequest[],
      onSuccess?: (data?: CreateReceiptReturnResponse) => void,
      onError?: () => void
    ): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      try {
        const { data } = await receiptReturnApi.apiReceiptReturnPost({
          receiptUid,
          receiptProducts,
        });

        if (onSuccess) {
          onSuccess(data);
        }
      } catch ({ response }) {
        const { data } = response as ApiError;

        if (onError) {
          onError();
        }

        dispatch(
          notificationActions.setNotification({
            message: data.title,
            severity: "error",
          })
        );
      }
    },
  getReceiptReturn:
    (
      receiptReturnUid: string,
      onSuccess?: (receipt?: ReceiptReturnResponse) => void,
      onError?: (response?: ApiError) => void
    ): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      try {
        const { data } = await receiptReturnApi.apiReceiptReturnUidGet(
          receiptReturnUid
        );

        dispatch(actionCreators.setReceiptReturn(data));

        if (onSuccess) {
          onSuccess(data);
        }
      } catch ({ response }) {
        const { data } = response as ApiError;

        if (onError) {
          onError(response as ApiError);
        } else {
          dispatch(
            notificationActions.setNotification({
              message: data.title,
              severity: "error",
            })
          );
        }
      }
    },
  sendReceiptReturn:
    (
      receiptReturnUid: string,
      email: string,
      customerName: string,
      onSuccess?: () => void,
      onError?: () => void
    ): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      try {
        await receiptReturnApi.apiReceiptReturnSendEmailPost({
          receiptReturnUid,
          email,
          customerName,
        });

        if (onSuccess) {
          onSuccess();
        }

        dispatch(
          notificationActions.setNotification({
            message: "Чек повернення надіслано",
            severity: "success",
          })
        );
      } catch ({ response }) {
        if (onError) {
          onError();
        }

        const { data } = response as ApiError;

        dispatch(
          notificationActions.setNotification({
            message: data.title,
            severity: "error",
          })
        );
      }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultState: ReceiptReturnState = {
  searchReceiptResult: null,
  productsToReturn: null,
  receiptReturn: null,
};

export const reducer: Reducer<ReceiptReturnState> = (
  state: ReceiptReturnState = defaultState,
  incomingAction: Action
): ReceiptReturnState => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case ReceiptReturnActionTypes.SET_SEARCH_RECEIPT_RESULT:
      return { ...state, searchReceiptResult: action.payload };
    case ReceiptReturnActionTypes.SET_PRODUCTS_TO_RETURN:
      return { ...state, productsToReturn: action.payload };
    case ReceiptReturnActionTypes.SET_RECEIPT_RETURN:
      return { ...state, receiptReturn: action.payload };
    default:
      return state;
  }
};
export default null;
