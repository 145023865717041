import React, { FC, useMemo } from "react";
import { connect } from "react-redux";
import { NumberFormatValues } from "react-number-format";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";

import { ReportTable, HeadCell, Row } from "components/Table";
import { CustomNumericFormat } from "components/FormInput";

import { ReactComponent as IconMinus } from "assets/images/icon_minus.svg";
import { ReactComponent as IconPlus } from "assets/images/icon_plus.svg";

import { ReceiptResponse } from "api";
import { ApplicationState } from "store";
import { actionCreators, ProductToReturn } from "store/ReceiptReturn";

import { getPriceFormat } from "shared/functions";

import theme from "theme";

interface ReduxProps {
  setProductsToReturn: (
    productsToReturn: Map<number, ProductToReturn> | null
  ) => void;
  productsToReturn: Map<number, ProductToReturn> | null;
}

interface Props {
  receipt: ReceiptResponse;
  selected: Row[];
  setSelected: (selected: Row[]) => void;
}

type AllProps = ReduxProps & Props;

const Table: FC<AllProps> = (props) => {
  const { productsToReturn, selected, setSelected, setProductsToReturn } =
    props;
  const products = useMemo(
    () => Array.from(productsToReturn?.values() || []),
    [productsToReturn]
  );

  const onChangeQuantity = (row: Row, quantity: number) => {
    const {
      receiptProductId,
      receiptProductQuantity,
      name,
      unit,
      price,
      totalAmount,
      discountAmount,
    } = row;

    const updatedProduct: ProductToReturn = {
      receiptProductId,
      receiptProductQuantity,
      quantity: null,
      name,
      unit,
      price,
      totalAmount,
      discountAmount,
    };

    if (!(quantity < 0 || isNaN(quantity))) {
      updatedProduct.quantity = quantity;
    }

    const updatedProductsToReturn = new Map(productsToReturn).set(
      row.receiptProductId,
      updatedProduct
    );

    const selectedProductIndex = selected.indexOf(row);
    const isProductSelected = selectedProductIndex !== -1;

    setProductsToReturn(updatedProductsToReturn);

    if (isProductSelected) {
      const updatedSelected = selected.map((value, index) =>
        index === selectedProductIndex ? updatedProduct : value
      );
      setSelected(updatedSelected);
    }
  };

  const onBlurQuantity = (row: Row, quantity: number) => {
    if (quantity < 0 || isNaN(quantity)) {
      onChangeQuantity(row, 1);
    }
  };

  const onDecrement = (row: Row) => {
    const quantity = Number(row.quantity) - 1;
    onChangeQuantity(row, quantity);
  };

  const onIncrement = (row: Row) => {
    const quantity = Number(row.quantity) + 1;
    onChangeQuantity(row, quantity);
  };

  const onSelectedChange = (selected: Row[]) => {
    setSelected(selected);
  };

  const isAllowed = (row: Row) => (values: NumberFormatValues) => {
    const { receiptProductQuantity } = row;
    const value = values.floatValue || 0;

    return value <= receiptProductQuantity;
  };

  const columns: HeadCell[] = [
    {
      id: "name",
      label: "Назва товару",
      width: "70%",
    },
    {
      id: "edit",
      label: "",
      width: "15%",
      cell: (cell, row) => {
        return (
          <Box display="flex">
            <IconButton
              disableRipple
              disableTouchRipple
              onClick={() => onDecrement(row)}
              disabled={row.quantity <= 1}
            >
              <IconMinus
                color={
                  row.quantity <= 1
                    ? theme.colors.primaryGrey
                    : theme.colors.body
                }
              />
            </IconButton>
            <TextField
              sx={{ width: "70px" }}
              InputProps={{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                inputComponent: CustomNumericFormat as any,
                inputProps: {
                  decimalScale: 3,
                  style: { textAlign: "center" },
                  isAllowed: isAllowed(row),
                },
                value: row.quantity,
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onChangeQuantity(row, parseFloat(event.target.value))
              }
              onBlur={(event: React.FocusEvent<HTMLInputElement, Element>) =>
                onBlurQuantity(row, parseFloat(event.target.value))
              }
              value={row.quantity}
            />
            <IconButton
              disableRipple
              disableTouchRipple
              onClick={() => onIncrement(row)}
              disabled={row.quantity >= row.receiptProductQuantity}
            >
              <IconPlus
                color={
                  row.quantity >= row.receiptProductQuantity
                    ? theme.colors.primaryGrey
                    : theme.colors.body
                }
              />
            </IconButton>
          </Box>
        );
      },
    },
    {
      id: "unit",
      label: "",
      width: "15%",
      cell: (cell, row) => {
        return (
          <Box textAlign="left">{`${row.unit} x ${getPriceFormat(
            row.price
          )}₴`}</Box>
        );
      },
    },
  ];

  return (
    <ReportTable
      idName="receiptProductId"
      rows={products}
      columns={columns}
      checkboxSelection
      selected={selected}
      onSelectedChange={onSelectedChange}
    />
  );
};

const mapStateToProps = ({ receiptReturn }: ApplicationState) => ({
  productsToReturn: receiptReturn.productsToReturn,
});

const mapDispatchToProps = {
  setProductsToReturn: actionCreators.setProductsToReturn,
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
