import { Action, Reducer } from "redux";
import {
  CreateIbanQrInvoiceRequestDto,
  IbanQrInvoiceApi,
  IbanQrInvoiceResponseDto,
} from "../api";
import { AppThunkAction } from "./";
import { ApiError } from "types";

import {
  SetNotificationAction,
  actionCreators as notificationActions,
} from "./Notification";

const ibanQrinvoiceApi = new IbanQrInvoiceApi();

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IbanQrState {
  iban: IbanQrInvoiceResponseDto | null;
}

enum IbanQrActionTypes {
  SET_IBAN_QR = "SET_IBAN_QR",
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SetIbanQrAction {
  type: IbanQrActionTypes.SET_IBAN_QR;
  payload: IbanQrInvoiceResponseDto | null;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SetIbanQrAction | SetNotificationAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  setIbanQr: (payload: IbanQrInvoiceResponseDto | null): SetIbanQrAction => ({
    type: IbanQrActionTypes.SET_IBAN_QR,
    payload,
  }),
  getIbanQr:
    (ibanInvoiceUid: string): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      try {
        const { data } = await ibanQrinvoiceApi.apiIbanQrIbanInvoiceUidGet(
          ibanInvoiceUid
        );
        dispatch(actionCreators.setIbanQr(data));
      } catch ({ response }) {
        const { data } = response as ApiError;

        dispatch(
          notificationActions.setNotification({
            message: data.title,
            severity: "error",
          })
        );
      }
    },
  createIbanQr:
    (
      body: CreateIbanQrInvoiceRequestDto,
      onSuccess?: () => void,
      onError?: () => void
    ): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      try {
        const { data } = await ibanQrinvoiceApi.apiIbanQrPost(body);
        dispatch(actionCreators.setIbanQr(data));

        if (onSuccess) {
          onSuccess();
        }
      } catch ({ response }) {
        const { data } = response as ApiError;

        if (onError) {
          onError();
        }

        dispatch(
          notificationActions.setNotification({
            message: data.title,
            severity: "error",
          })
        );
      }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultState: IbanQrState = {
  iban: null,
};

export const reducer: Reducer<IbanQrState> = (
  state: IbanQrState = defaultState,
  incomingAction: Action
): IbanQrState => {
  const action = incomingAction as KnownAction;

  switch (action.type) {
    case IbanQrActionTypes.SET_IBAN_QR:
      return { ...state, iban: action.payload };
    default:
      return state;
  }
};
