import React, { FC, useRef } from "react";
import { useFormContext } from "react-hook-form";

import { CustomNumericFormat, InputEvent } from "../index";
import StyledFormInput from "./FormInput";

import { TextInputProps } from "../types";

const StyledFormCashInput: FC<TextInputProps> = (props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { setValue } = useFormContext();

  const onChange = ({ target }: InputEvent) => {
    setValue(props.name, target.value);
  };

  return (
    <StyledFormInput
      defaultValue=""
      inputRef={inputRef}
      rules={{
        onChange,
      }}
      InputProps={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inputComponent: CustomNumericFormat as any,
      }}
      {...props}
    />
  );
};

export default StyledFormCashInput;
