import React, { useState, useMemo, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { History } from 'history';
import { lighten, Theme } from '@mui/system';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { PointOfSaleState, actionCreators as actionCreatorsPointOfSale } from 'store/PointOfSale';
import Table, { PartnershipTypes } from 'containers/BusinessStructure/Table';
import KeyUploadModal from 'containers/BusinessStructure/KeyUploadModal';
import { actionCreators } from 'store/CompanyPartnership';
import PartnerModal from './Partner';
import Select, { Value } from 'components/Select';
import { PartnerResponseDto } from 'api/models'
import analytics from 'services/analytics';
import { AnalyticsEvents } from 'shared/constants/analytics';

type ReduxProps = Pick<PointOfSaleState, 'points'> & { history: History };

const BusinessStructure: React.FC<ReduxProps> = () => {
  const initPartner = { ownerPhone: '', companyUid: '', companyName: '', partnershipType: 0 };
  const [keyUploadModalOpen, setKeyUploadModalOpen] = useState(false);
  const [partnerModalOpen, setPartnerModalOpen] = useState(false);
  const partners = useSelector((state) => state.companyPartnership.partners);
  const [partner, setPartner] = useState<PartnerResponseDto>(initPartner);
  const { partnershipType, companyUid: partnerCompanyUid } = partner;

  const points = useSelector(state => state.pointOfSale.points.filter(({ pointOfSaleId }) => pointOfSaleId != 0));
  const partnerPoints = useSelector(state => state.pointOfSale.partnerPoints.filter(({ pointOfSaleId }) => pointOfSaleId != 0));
  const { hasDigitalSign } = useSelector((state) => state.user.data);
  const hasSfsData = useMemo(() => !points.length || points.some(({ isSfsIntegrationEnabled }) => isSfsIntegrationEnabled), [points]);
  const alertSx = ({ breakpoints }: Theme) => ({ [breakpoints.down('sm')]: { flexWrap: 'wrap' } });
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(actionCreators.getCompanyPartners());
  }, []);

  useEffect(() => {
    if (partners.length) {
      const [firstPartner] = partners;

      setPartner(firstPartner);
    }
  }, [partners]);

  useEffect(() => {
    if (partnerCompanyUid) {
      dispatch(actionCreatorsPointOfSale.getPointsOfSaleForPartner(partnerCompanyUid));
    }
  }, [partner]);

  const onKeyUploadClick = () => {
    setKeyUploadModalOpen(true);
    analytics().sendEvent(AnalyticsEvents.business_structure_upload_key);
  };

  const handlePartnerChange = (value: Value) => {
    const selectedPartner = partners.find(({ companyUid }) => value == companyUid) || initPartner;

    setPartner(selectedPartner);
  };

  const onCloseModal = () => setPartnerModalOpen(false);
  const onOpenModal = () => setPartnerModalOpen(true);

  return (
    <>
      <KeyUploadModal open={keyUploadModalOpen} onClose={() => setKeyUploadModalOpen(false)} />
      <Box
        sx={{
          bgcolor: (theme) => lighten(theme.palette.warning.light, 0.9),
          width: '100%',
          position: 'relative',
          right: '0',
        }}
      >
        <Container>
          {!hasDigitalSign && (
            <Alert
              sx={alertSx}
              severity="warning"
              icon={false}
              action={<Button size='large' onClick={onKeyUploadClick} sx={{ alignSelf: 'center', minWidth: 150 }}>Завантажити ключ</Button>}
            >
              <AlertTitle>Будь ласка, завантажте ключ.</AlertTitle>
              Ключ КЕП/ЕЦП потрібен, щоби завантажити дані з Державної фіскальної служби та надалі видавати фіскальні чеки. У деяких випадках ключ складається з двох файлів. Завантажте обидва файли за наявності.
              <br />
              Якщо у вас з’явилися запитання, зверніться до нашої Служби турботи
            </Alert>
          )}
          {hasDigitalSign && !hasSfsData && (
            <Alert
              sx={alertSx}
              severity="warning"
              icon={false}
              action={<Button size='large' onClick={onKeyUploadClick} sx={{ alignSelf: 'center', minWidth: 199 }}>Завантажити новий ключ</Button>}
            >
              <AlertTitle>Нажаль у вас немає жодної заєстрованого РРО в податковій.</AlertTitle>
              Нажаль, ми не знайшли відомостей про актуальні реєстрацію РРО на сайті ДПС України.
              <br />
              Реєстрація ПРРО проводиться на підставі заяви про реєстрацію програмних реєстраторів розрахункових операцій за формою № 1-ПРРО (J/F1316602). ПРРО можна зареєструвати через Електронний кабінет платника податків на сайті ДПС України.
            </Alert>
          )}
        </Container>
      </Box>
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Typography variant='h4' mt={3} mb={2} component='h1'>Мій бізнес</Typography>
        <Button
          onClick={() => {
            onOpenModal();
            analytics().sendEvent(AnalyticsEvents.business_structure_add_partner);
          }}
          size='large'
        >
          Додати партнера
        </Button>
      </Box>
      <Box mb={2} alignItems='center'>
        <Typography variant='body2' mb={1}>Бізнес:</Typography>
        <Select
          items={partners.map(({ companyName, companyUid }) => ({ value: companyUid, label: companyName }))}
          value={partner.companyUid}
          onChange={handlePartnerChange}
          sx={{ fontSize: 24 }}
        />
        { partner.partnershipType == PartnershipTypes.partner &&
          <Button
            onClick={() => {
              dispatch(actionCreators.setPartner(partner));
              onOpenModal();
              analytics().sendEvent(AnalyticsEvents.business_structure_partner_info);
            }}
            variant='contained'
            color='secondary'
            size='large'
            sx={{ marginLeft: 2 }}
          >
            Інформація
          </Button>
        }
      </Box>
      {partnerPoints.map(({ pointOfSaleId = 0, name  }) => (
        <Box key={pointOfSaleId} >
          <Typography variant='body2' mb={1}>Торгова точка:</Typography>
          <Typography variant='h3' mb={2}>{name}</Typography>
          <Table pointOfSaleId={pointOfSaleId} companyUid={partnerCompanyUid} partnershipType={partnershipType} />
        </Box>
      ))}
      {partner.partnershipType == PartnershipTypes.allBusiness &&
          <Table pointOfSaleId={0} companyUid={partnerCompanyUid} partnershipType={partnershipType} />
       }
      <PartnerModal
        {...{ onCloseModal, isOpenModal: partnerModalOpen }}
      />
    </>
  )
};

export default BusinessStructure;
