import { blue400, grey300, grey500, grey800, grey900, red800 } from "styles";

export const styles = {
  inputTitle: {
    color: grey800,
  },
  input: {
    "& .MuiInputBase-input": {
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: 0,

      color: grey900,
      borderRadius: "8px",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: grey300,

      "& fieldset": {
        borderColor: grey300,
      },
      "&:hover fieldset": {
        borderColor: grey500,
      },
      "&.Mui-focused fieldset": {
        borderColor: blue400,
      },
      "&.Mui-error": {
        borderColor: red800,

        "& fieldset": {
          borderColor: red800,
        },

        "& .MuiInputBase-input": {
          color: red800,

          "&:focus": {
            color: grey900,
          },
        },
      },
    },
    "& MuiOutlinedInput-input:focus": {
      color: grey900,
    },
    "& .MuiFormHelperText-root.Mui-error": {
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: 0,
      color: red800,
    },
  },
};
