import { RouterState } from 'connected-react-router';
import * as PointOfSale from './PointOfSale';
import * as CashRegister from './CashRegister';
import * as CashRegistersOverview from './CashRegisterOverview';
import * as WorkShift from './WorkShift';
import * as Receipt from './Receipt';
import * as ReceiptReturn from './ReceiptReturn';
import * as Report from './Report';
import * as Authorization from './Authorization';
import * as Registration from './Registration';
import * as User from './User';
import * as DigitalSign from './DigitalSign';
import * as CashiersState from './Cashiers';
import * as Accountants from './Accountants';
import * as Product from './Product';
import * as Order from './Order';
import * as Channel from './Channel';
import * as Category from './Category';
import * as Company from './Company';
import * as Notification from './Notification';
import * as CompanyPartnership from './CompanyPartnership';
import * as Subscription from './Subscription';
import * as Settings from './Settings';
import * as Acquiring from './Acquiring';
import * as Drawer from './Drawer';
import * as PreOrder from './PreOrder';
import * as IbanQr from './IbanQr';

// The top-level state object
export interface ApplicationState {
    router: RouterState;
    pointOfSale: PointOfSale.PointOfSaleState;
    cashRegister: CashRegister.CashRegisterState;
    cashRegistersOverview: CashRegistersOverview.CashRegistersOverviewState;
    workShift: WorkShift.WorkShiftState;
    receipt: Receipt.ReceiptState;
    receiptReturn: ReceiptReturn.ReceiptReturnState;
    reports: Report.ReportState;
    authorization: Authorization.AuthenticationState;
    registration: Registration.RegistrationState;
    user: User.UserState;
    digitalSign: DigitalSign.DigitalSignState;
    cashiers: CashiersState.CashiersState;
    accountants: Accountants.AccountantsState;
    product: Product.ProductState;
    order: Order.OrderState;
    channel: Channel.ChannelState;
    category: Category.CategoryState;
    company: Company.CompanyState;
    notification: Notification.NotificationState;
    companyPartnership: CompanyPartnership.CompanyPartnershipState;
    subscription: Subscription.SubscriptionState;
    settings: Settings.SettingsState;
    acquiring: Acquiring.AcquiringState;
    drawer: Drawer.DrawerState;
    preOrder: PreOrder.PreOrderState;
    ibanQr: IbanQr.IbanQrState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    pointOfSale: PointOfSale.reducer,
    cashRegister: CashRegister.reducer,
    cashRegistersOverview: CashRegistersOverview.reducer,
    workShift: WorkShift.reducer,
    receipt: Receipt.reducer,
    receiptReturn: ReceiptReturn.reducer,
    reports: Report.reducer,
    authorization: Authorization.reducer,
    registration: Registration.reducer,
    user: User.reducer,
    digitalSign: DigitalSign.reducer,
    cashiers: CashiersState.reducer,
    accountants: Accountants.reducer,
    product: Product.reducer,
    order: Order.reducer,
    channel: Channel.reducer,
    category: Category.reducer,
    company: Company.reducer,
    notification: Notification.reducer,
    companyPartnership: CompanyPartnership.reducer,
    subscription: Subscription.reducer,
    settings: Settings.reducer,
    acquiring: Acquiring.reducer,
    drawer: Drawer.reducer,
    preOrder: PreOrder.reducer,
    ibanQr: IbanQr.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): Promise<void>;
}
